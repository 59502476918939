<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Select the appropriate classification (name) for each of the following colloidal substances.
      </p>

      <v-simple-table>
        <thead>
          <tr v-for="(molecule, i) in molecules" :key="i">
            <td>
              <stemble-latex :content="molecule" />
            </td>
            <td style="text-align: center">
              <v-select
                v-model="inputs[`input${i + 1}`]"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question420',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
      },
      items: [
        {text: 'Gel', value: 'gel'},
        {text: 'Sol', value: 'sol'},
        {text: 'Emulsion', value: 'emulsion'},
        {text: 'Foam', value: 'foam'},
        {text: 'Aerosol', value: 'aerosol'},
      ],
      mol1options: [
        'Whipped cream (made with agitation to disperse air throughout the liquid cream)',
        'Marshmallow (made primarily from solid sugar that is whipped to disperse air throughout)',
      ],
      mol2options: [
        'Block of Cheese (a solid which has liquid water dispersed throughout)',
        'Stick of Butter (a solid which has liquid water dispersed throughout)',
      ],
      mol3options: [
        'Hairspray (commonly consisting of a liquid polymer compound dispersed within a gaseous propellant)',
        'Fog (the result of water vapour in the air condensing into microscopic droplets)',
      ],
      mol4options: [
        'Paint (solid, coloured pigment particles dispersed throughout the liquid paint medium)',
        'Ink (solid, coloured pigment particles dispersed throughout the liquid ink medium)',
      ],
      mol5options: [
        'Oil in water (such a combination is frequently used to make mayonnaise)',
        'Milk (a liquid containing dispersed liquid fat)',
      ],
    };
  },
  computed: {
    object1() {
      return this.taskState.getValueBySymbol('object1').content;
    },
    object2() {
      return this.taskState.getValueBySymbol('object2').content;
    },
    object3() {
      return this.taskState.getValueBySymbol('object3').content;
    },
    object4() {
      return this.taskState.getValueBySymbol('object4').content;
    },
    object5() {
      return this.taskState.getValueBySymbol('object5').content;
    },
    molecules() {
      return [
        this.mol1options[this.object1.value - 1],
        this.mol2options[this.object2.value - 1],
        this.mol3options[this.object3.value - 1],
        this.mol4options[this.object4.value - 1],
        this.mol5options[this.object5.value - 1],
      ];
    },
  },
};
</script>
